"use strict";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
if (!Array.prototype.includes) {
  Array.prototype.includes = function (searchElement /*, fromIndex*/) {
    'use strict';

    var O = Object(this);
    var len = parseInt(O.length) || 0;
    if (len === 0) {
      return false;
    }
    var n = parseInt(arguments[1]) || 0;
    var k;
    if (n >= 0) {
      k = n;
    } else {
      k = len + n;
      if (k < 0) {
        k = 0;
      }
    }
    var currentElement;
    while (k < len) {
      currentElement = O[k];
      if (searchElement === currentElement || searchElement !== searchElement && currentElement !== currentElement) {
        // NaN !== NaN
        return true;
      }
      k++;
    }
    return false;
  };
}
var formLocalStorageKey = "_eco";
var isRecoveryPage = window.location.href.indexOf('form-recovery') !== -1;
var debugMode = false;
var clearLocalStorage = false;
var adddays = 2;
var showBasicDatePicker = supportsTouch;
var currentLang = $('body').attr('data-current-lang');
var formsent = false;
// Default prices that will be replaced by the AJAX request.
var prices = {
  insurance: 2.4,
  express_processing: 10,
  currency: 'EUR'
};
var showConsultationWidget = false;
var southAmericaNoneOfAbove = false;
var visaPlaceNationality = false;
var currentFields = 1;
var emojiRegex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
var availableNationalityFieldNames = ['nationality', 'applicant-adress-country', 'nationality-current', 'contact-country', 'passport-country', 'passport-issue-country', 'passport-place-of-issue'];
document.addEventListener("input", function (event) {
  document.querySelectorAll('input, textarea').forEach(function (input) {
    input.value = input.value.replace(emojiRegex, '');
  });
}, false);
function isSupportForDateInput() {
  $('body').append('<input id="hiddenInput" type="date" value="text-value">');
  var isSupport = $('#hiddenInput').val() !== 'text-value';
  $('#hiddenInput').remove();
  return isSupport;
}
if (supportsTouch) {
  if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent) || !isSupportForDateInput()) {
    showBasicDatePicker = false;
  }
}
function addToToday(days, isBaseDatePicker) {
  var today = new Date(new Date().getTime() + days * 24 * 60 * 60 * 1000);
  var dd = ("0" + today.getDate()).slice(-2);
  var mm = ("0" + (today.getMonth() + 1)).slice(-2);
  var yyyy = today.getFullYear();
  if (isBaseDatePicker) {
    return yyyy + '-' + mm + '-' + dd;
  } else {
    return dd + '/' + mm + '/' + yyyy;
  }
}
function hiddenFormCalendar() {
  var $formSelector = $('[name="form[in7days]"]');
  $formSelector.change(function () {
    var $field1 = $('.flight-date');
    var cal = $("#form-field-flight-date");
    if ($formSelector.prop('checked') == true) {
      $field1.removeClass('hidden');
      cal.attr('data-required', 'true');
    } else {
      $field1.addClass('hidden');
      cal.removeAttr('data-required');
      $field1.find('input').val('').removeClass('with-success').removeClass('with-error');
    }
  }).trigger('change');
}
function isSafeModeOn() {
  var status;
  $.ajax({
    async: false,
    url: '//' + window.location.host + '/safeModeStatus/',
    method: "GET",
    cache: false,
    success: function success(response) {
      status = parseInt(response);
    },
    error: function error() {
      console.log("I can't check safe mode status!");
    }
  });
  return status > 0;
}
function isVisaPlaceNationality() {
  var nationality = $('#form-field-passport-country').val();
  var excludedNationalitiesVisaPlace = [null, '13339',
  // Andorra
  '13494',
  //Argentina
  '13446',
  // Australia
  '13287',
  // Austria
  '13473',
  // Bahamas
  '13470',
  // Barbados
  '13288',
  // Belgium
  '13495',
  // Brazil
  '13428',
  // Brunei
  '13340',
  // Bulgaria
  '13497',
  // Chile
  '13316',
  // Croatia
  '13414',
  // Cyprus
  '13291',
  // Czech Republic
  '13293',
  // Denmark
  '13294',
  // Estonia
  '13297',
  // Finland
  '13298',
  // France
  '13299',
  // Germany
  '13300',
  // Greece
  '13405',
  // Hong Kong
  '13401',
  // Hong Kong SAR
  '13301',
  // Hungary
  '13342',
  // Iceland
  '13302',
  // Ireland
  '13407',
  // Israel
  '13303',
  // Italy
  '13408',
  // Japan
  '13431',
  // Korea, Republic of
  '13295',
  // Latvia
  '13343',
  // Liechtenstein
  '13296',
  // Lithuania
  '13289',
  // Luxembourg
  '13304',
  // Malta
  '13453',
  // Mexico
  '13344',
  // Monaco
  '13305',
  // Netherlands
  '13447',
  // New Zealand
  '13306',
  // Norway
  '13449',
  // Papua New Guinea
  '13307',
  // Poland
  '13308',
  // Portugal
  '13345',
  // Romania
  '13526',
  // Samoa (American (13525) + Independent state (13526))
  '13346',
  // San Marino
  '13292',
  // Slovakia
  '13319',
  // Slovenia
  '13512',
  // Solomon Islands
  '13311',
  // Spain
  '13313',
  // Sweden
  '13314',
  // Switzerland
  '13404',
  // Taiwan
  '13444',
  // United Arab Emirates
  '13277',
  // United Kingdom
  '13347',
  // Holy See (Vatican City State)
  '13472',
  // Antigua and Barbuda
  '13459',
  // Costa Rica
  '13358',
  // Morocco
  '13464',
  // Panama
  '13420',
  // Philippines
  '13481',
  // St. Vincent and the Grenadines
  '13533',
  // Seychelles
  '13439',
  // Thailand
  '13469',
  // Trinidad and Tobago
  '13500',
  // Uruguay
  '13479',
  // St. Kitts and Nevis
  '13480',
  // St. Lucia
  '13280',
  // British Overseas Citizen
  '13286',
  // British National Overseas
  '13281',
  // British Dependent Ter. Cit
  '13279',
  // British Citizen
  '13424' // Singapore
  ];

  visaPlaceNationality = !excludedNationalitiesVisaPlace.includes(nationality);
}
function setDateTypeField() {
  if (document.getElementById('form-field-contact-phone')) {
    document.getElementById('form-field-contact-phone').type = 'tel';
  }
  var ftoday = new Date(),
    fyyyy = ftoday.getFullYear(),
    futureyear = fyyyy + 5;
  if (showBasicDatePicker) {
    var minDate = new Date(ftoday.getTime() + adddays * 24 * 60 * 60 * 1000);
    var minDateMonth = minDate.getMonth() + 1;
    var minDateDay = minDate.getDate();
    if (minDateMonth < 10) {
      minDateMonth = '0' + minDateMonth;
    }
    if (minDateDay < 10) {
      minDateDay = '0' + minDateDay;
    }
    $('#form-field-flight-date').attr({
      'type': 'date',
      'min': minDate.getFullYear() + '-' + minDateMonth + '-' + minDateDay,
      'max': futureyear + '-' + minDateMonth + '-' + minDateDay
    });
  } else {
    $('#form-field-flight-date').datepicker({
      minDate: +adddays,
      dateFormat: "dd/mm/yy",
      numberOfMonths: window.innerWidth > 760 ? 2 : 1
    });
    $(".disable-input input").attr('readonly', true);

    // Protection against google translator
    $('.ui-datepicker').addClass('notranslate');
  }
  window.insuranceEnabled && addInsuranceDatepicker();
}
function setCalendarSettings() {
  $.ajax({
    url: '/getCalendar/',
    method: "GET",
    async: false,
    success: function success(result) {
      result = JSON.parse(result);
      if (typeof result.settings.flightOffset !== 'undefined') {
        adddays = parseInt(result.settings.flightOffset);
      }
      setDateTypeField();
    },
    error: function error() {
      setDateTypeField();
    }
  });
}
function additionalPassportQuestions() {
  var objVal = $('#form-field-passport-country').val();
  var optionStr;
  if (!!objVal) {
    optionStr = $('#form-field-passport-country option[value=' + objVal + ']').val();
  }
  var uscisCheckBox = $("input[value='3'][name='form\\[question-brazil\\]']");
  var visaCheckBox = $("input[value='2'][name='form\\[question-brazil\\]']");
  $('.additional').addClass('hidden');
  switch (optionStr) {
    case '13472':
    case '13494':
    case '13459':
    case '13358':
    case '13464':
    case '13420':
    case '13479':
    case '13480':
    case '13481':
    case '13533':
    case '13439':
    case '13469':
    case '13500':
    case '13453':
    case '13495':
      $('.question-brazil-content').removeClass('hidden');
      if (uscisCheckBox.is(':checked')) {
        $('.additional-USCIS-question-brazil-content').removeClass('hidden');
      }
      if (visaCheckBox.is(':checked')) {
        $('.additional-VISA-question-brazil-content').removeClass('hidden');
      }
      break;
    case '13404':
      $('.question-taiwan-content').removeClass('hidden');
      break;
    default:
      southAmericaNoneOfAbove = false;
      $('input[name="form[question-brazil]"]:checked').prop('checked', false);
      formPartnerWidget();
  }
}
function isSouthAmericaAndNoneOfAbove() {
  var inputValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var isVisaPlace = false;
  $('.additional2').addClass('hidden');
  $('.additional2 input, .additional2 select').val('').trigger('change');
  $('.question-brazil-content').removeClass('group-spacer');
  switch (inputValue) {
    case '3':
      $('.additional-USCIS-question-brazil-content').removeClass('hidden with-success with-error').find('input');
      break;
    case '1':
      $('.question-brazil-content').addClass('group-spacer');
      break;
    case '2':
      $('.additional-VISA-question-brazil-content').removeClass('hidden with-success with-error').find('input');
      break;
    case '4':
      isVisaPlace = true;
      break;
  }
  if ($('input[name="form[question-brazil]"]:checked').val() == 4) {
    isVisaPlace = true;
  }
  southAmericaNoneOfAbove = isVisaPlace;
}
$(function () {
  if (storageAvailable('localStorage') && (typeof localStorage === "undefined" ? "undefined" : _typeof(localStorage)) === 'object') {
    try {
      localStorage.setItem('localStorage', 1);
      localStorage.removeItem('localStorage');
    } catch (e) {
      Storage.prototype._setItem = Storage.prototype.setItem;
      Storage.prototype.setItem = function () {};
    }
  }

  // Empty old cookies
  setCookie("serialized-form", "", 3);
  hiddenForm1();
  hiddenForm2();
  hiddenForm3();
  hiddenForm4();
  hiddenForm5();
  hiddenForm6();
  invoiceHiddenForm();
  visaRejectedAndJailAnswer();

  // Load form.
  if (isRecoveryPage) {
    loadForm($('#apply-form-data').data('form'));
  } else {
    loadForm(false);
  }
  window.onload = function () {
    setTimeout(function () {
      scrollTo(0, -1);
    }, 0);
  };

  // Show and hide fields.
  hiddenFormCalendar();
  jobHiddenForm();
  minorHiddenForm();
  additionalCitizenshipHiddenForm();

  // Initialize on change basic fields.
  if (!isRecoveryPage) {
    $('#form-field-contact-email-repeat, #form-field-contact-email, #form-field-birth-day, #form-field-birth-month, #form-field-birth-year').on('change', function (e) {
      initializeApplication();
    });
  }

  // Validation on input
  $('#form input:not([type="checkbox"], [type="radio"]), #form textarea').on('input', function (e) {
    validateFieldsBefore($(this), e);
    validateField($(this), e);
  });
  $('#form-field-port-of-arrival').change(function () {
    toggleFastTrack();
  }).trigger('change');
  availableNationalityFieldNames.forEach(function (fieldName) {
    $("#form-field-".concat(fieldName)).on('change', function () {
      toggleFastTrack();
    });
  });
  $('[name="form[employment-position]"]').on('focus', function () {
    $('.employment-position-wrapper').removeClass('hidden');
  });
  $(window).on('click', function (e) {
    if (!e.target.classList.value.includes('employment-position-list-item') && e.target.getAttribute('id') != 'form-field-employment-position') {
      $('.employment-position-wrapper').addClass('hidden');
    }
  });
  professionsInputController();
  professionList();
  $('.AdditionalBrazilQuestions').on('change', function (event) {
    var inputValue = event.target.attributes.value ? event.target.attributes.value.value : null;
    isSouthAmericaAndNoneOfAbove(inputValue);
    formPartnerWidget();
  });
  additionalPassportQuestions();
  processForm();
  setCalendarSettings();
  $('.form-row:not(.expressProcessing) .with-hint').tooltip({
    track: true,
    content: function content() {
      return $(this).attr('title');
    }
  });

  //
  // cookiesWarning
  //
  cookiesWarning();

  //
  //  Form label  equal height
  //
  formLabelsEqual();

  //
  // Terms read more
  //
  $('.terms-read-more').click(function (e) {
    var $terms = $('.accept-terms-long');
    $terms.toggleClass('full-text');
  });
  cardProcessingForm();

  //
  // Payment form rwd
  //
  var $paymentForm = $('#payment-form');
  var $colFirstName = $('.col-first-name');
  var $colLastName = $('.col-last-name');
  if ($paymentForm.length && $(window).width() <= 720) {
    $colLastName.insertAfter($colFirstName);
  }

  //
  // show application no if storage isset
  //
  if (!isRecoveryPage) {
    showApplicationNo();
  }
  isSouthAmericaAndNoneOfAbove();
  formPartnerWidget();

  // Update on change

  $('#form input:not([type="checkbox"], [type="radio"]), #form textarea').on('change', function (e) {
    saveApplicationInBase();
  });
  $('form input[type=radio]').on('change', function (e) {
    validateField($(this), e);
    saveApplicationInBase();
  });
  $('#form select, #form input[type="checkbox"], #form input[type="radio"], #form #form-field-flight-date').on('change', function (e) {
    validateFieldsBefore($(this), e);
    validateField($(this), e);
    saveApplicationInBase();
  });
  $('[id^=form-field-additional-citizenship-new-]').on('change', function (e) {
    var selectedc = $(this).find(":selected").text();
    if (selectedc == 'Canada') {
      alert('Canadian citizens, including dual citizens, cannot apply for an eTA to enter Canada. Starting November 10 you will need a valid Canadian passport to board your flight to Canada.');
      $(this).prop('selectedIndex', 0);
    }
  });
  $('#form-field-passport-country').on('change', function () {
    additionalPassportQuestions();
    formPartnerWidget();
  });
});
function passportNumberHelper() {
  var $field = $('[name="form[passport-number]"], [name="check[passport_number]"]');
  var validChars = new RegExp("[a-zA-Z0-9]");
  $field.on('keypress', function (e) {
    var key = e.keyCode ? e.keyCode : e.which;
    var _char = String.fromCharCode(key);
    if (!validChars.test(_char) && key != 8) {
      e.preventDefault();
      return;
    }
  }).trigger('keypress');
  $field.on('keyup', function () {
    $field.val($field.val().toUpperCase());
  });
}
function professionsInputController() {
  var professionInput = $('#form-field-employment-position');
  professionInput.on('keyup', function (e) {
    $('.employment-position-wrapper').removeClass('hidden');
    var allProfessions = $('.employment-position-list-item');
    allProfessions.each(function (i, profession) {
      if (!profession.innerText.toLowerCase().includes(professionInput.val().toLowerCase()) && profession.getAttribute('value') != '') {
        profession.classList.add('hidden');
      } else {
        profession.classList.remove('hidden');
      }
    });
    var noResults = !allProfessions.text().toLowerCase().includes(professionInput.val().toLowerCase()) && professionInput.val() != '';
    if (noResults) {
      $('.no-results').removeClass('hidden');
    } else {
      $('.no-results').addClass('hidden');
    }
    assignProfessionInputTextToValue(professionInput);
    validateField(professionInput);
  });
}
function professionList() {
  var professions = $('.employment-position-list-item:not([disabled])');
  var professionInput = $('#form-field-employment-position');
  professions.on('click', function (e) {
    professionInput.val(e.target.innerText);
    assignProfessionInputTextToValue(professionInput);
    validateField(professionInput);
    $('.employment-position-wrapper').addClass('hidden');
  });
}
function assignProfessionInputTextToValue(input) {
  $('#form-field-employment-position-hidden option').each(function (i, option) {
    if (option.innerText.toLowerCase() === input.val().toLowerCase() && input.val() != '------') {
      $('#form-field-employment-position-hidden').val(option.value);
    }
  });
}
function cardProcessingForm() {
  var $form = $('#card-processing-form');
  var $btn = $form.find('[type="submit"]');
  var $message = $('.payment-status');
  $form.submit(function (e) {
    e.preventDefault();
    $btn.prop('disabled', true).addClass('loading');
    $message.html("").removeClass('show-error').removeClass('show-success');
    $.ajax({
      url: $form.attr('action'),
      method: "POST",
      dataType: "json",
      data: $form.serialize(),
      success: function success(response) {
        if (response.status == true) {
          window.location.href = $form.attr('data-success-redirect');
          $form.hide();
          $message.html(translations['transaction-response settled']).removeClass('show-error').addClass('show-success');
        } else {
          $message.html(response.message).removeClass('show-success').addClass('show-error');
          $btn.prop('disabled', false).removeClass('loading');
        }
      },
      error: function error(response) {
        console.log(response.responseText);
        $btn.prop('disabled', false).removeClass('loading');
      }
    });
  });
}
function formLabelsEqual() {
  if ($(window).width() <= 400) {
    return;
  }
  var maxHeight = 0;
  var currentHeight = 0;
  var $columns;
  $('#form .form-row-fields').each(function () {
    maxHeight = 0;
    $columns = $(this).find('.fields-column:visible:not(.noequalize)');
    $columns.each(function () {
      currentHeight = $(this).height();
      if (currentHeight > maxHeight) maxHeight = currentHeight;
    });
    if ($columns.length > 1) {
      $columns.each(function () {
        var $obj = $(this);
        currentHeight = $obj.height();
        if (currentHeight < maxHeight) {
          $obj.css('margin-top', maxHeight - currentHeight);
        }
      });
    }
  });
}
function loadForm(formData) {
  var retrieveData = null;
  $('#form select').val('');

  //prepare data
  if (formData !== false && _typeof(formData) === "object") {
    retrieveData = formData;
  } else {
    retrieveData = storageDataToObj();
    if (typeof retrieveData === "undefined" || retrieveData === "" || retrieveData === null || $('#form').length <= 0) {
      return;
    }
  }

  // load form field data
  if (retrieveData && retrieveData.fields && retrieveData.fields.form) {
    var formItems = Object.keys(retrieveData.fields.form);
    var lastRetrieveField = null;
    for (var i = 0; i < formItems.length; i++) {
      var $field = $('[name="form[' + formItems[i] + ']"]');
      if (retrieveData.fields.form[formItems[i]] == '' || retrieveData.fields.form[formItems[i]] == null || formItems[i] == 'payment-type') {
        continue;
      }
      if ($field.attr('type') == "radio") {
        $('[name="form[' + formItems[i] + ']"][value="' + retrieveData.fields.form[formItems[i]] + '"]').prop("checked", true).trigger('change');
        lastRetrieveField = $field.eq(0);
      } else if ($field.attr('type') == "checkbox") {
        if (retrieveData.fields.form[formItems[i]] != '') {
          $('[name="form[' + formItems[i] + ']"][value="' + retrieveData.fields.form[formItems[i]] + '"]').prop("checked", true).trigger('change');
        }
        lastRetrieveField = $field.eq(0);
      } else {
        if (['date-of-arrival'].concat(_toConsumableArray(insuranceDateFieldsArray)).indexOf(formItems[i]) !== -1) {
          var dateFieldSeparator = '.';
          if (!showBasicDatePicker) {
            dateFieldSeparator = '/';
          }
          var dateOfArrivalVal = retrieveData.fields.form[formItems[i]];

          /*
           * - date base format : rrrr-mm-dd
           * - date form format mobile: dd.mm.rrrr
           * - date form format desktop: dd/mm/rrrr
           *
           */

          if (dateOfArrivalVal) {
            var dateOfArrivalValArr = dateOfArrivalVal.split('-');
            if (!showBasicDatePicker) {
              dateOfArrivalVal = dateOfArrivalValArr[2] + dateFieldSeparator + dateOfArrivalValArr[1] + dateFieldSeparator + dateOfArrivalValArr[0];
            }
            retrieveData.fields.form[formItems[i]] = dateOfArrivalVal;
          } else {
            retrieveData.fields.form[formItems[i]] = '';
          }
          lastRetrieveField = $field;
        }
        if (formItems[i].indexOf('additional-citizenship-new') !== -1) {
          $('#form-field-' + formItems[i]).closest('.fields-column').removeClass('hidden');
          formItems[i] !== 'additional-citizenship-new-1' && currentFields++;
        }
        $field.val(retrieveData.fields.form[formItems[i]]).trigger('change').trigger('input');
        lastRetrieveField = $field;
      }
      if (lastRetrieveField.length > 0 && lastRetrieveField.is(':visible')) {
        validateField(lastRetrieveField);
        validateFieldsBefore(lastRetrieveField, null);
      }
    }
    var citizenship_fields = [].slice.call(document.querySelectorAll("select[id^='form-field-additional-citizenship-new-']"));
    for (var x = 0; x < citizenship_fields.length; x++) {
      var citizenship_field = $(citizenship_fields[x]);
      if (!citizenship_field.closest('.fields-column').hasClass('hidden')) {
        for (; currentFields < parseInt(citizenship_field.prop('id').split('form-field-additional-citizenship-new-')[1]); currentFields++) {
          $('#form-field-additional-citizenship-new-' + i).closest('.fields-column').removeClass('hidden');
        }
        break;
      }
    }
  }
}
function cookiesWarning() {
  var cookiesAccepted = getCookie("cookies") == "y";
  var $cookies = $('.cookie-warning');
  var $closeBtn = $('#cookie-close');
  if (!cookiesAccepted) $cookies.removeClass('hidden');
  $closeBtn.click(function (e) {
    e.preventDefault();
    setCookie("cookies", "y", 365);
    $cookies.addClass('hidden');
  });
}
function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + expires;
}
function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1);
    if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
  }
  return "";
}
function isValidDate(dateString) {
  // First check for the pattern
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) {
    return false;
  }

  // Parse the date parts to integers
  var parts = dateString.split("/");
  var day = parseInt(parts[0], 10);
  var month = parseInt(parts[1], 10);
  var year = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month == 0 || month > 12) {
    return false;
  }
  var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 == 0 || year % 100 != 0 && year % 4 == 0) {
    monthLength[1] = 29;
  }

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
}
function validateFieldsBefore($stopBefore, event) {
  $('#form input, #form select, #form textarea').each(function () {
    var $obj = $(this);
    if ($obj.attr('id') == $stopBefore.attr('id')) {
      return false;
    } else {
      validateField($obj);
    }
  });
}
function validateField($field) {
  var isValid = true;
  var fieldType = $field.attr('type');
  var isRequired = $field.attr('data-required') !== undefined;
  if ($field.length === 0) {
    return false;
  }
  if (fieldType == "checkbox") {
    if ($field.prop('checked') == false) {
      isValid = false;
    }
  } else if (fieldType == "radio") {
    var v = $('[name="' + $field.attr('name') + '"]:checked').val();
    if (v == null || typeof v == "undefined") {
      isValid = false;
    }
  } else {
    if ($field.val() == null || typeof $field.val() === "undefined") {
      isValid = false;
    } else {
      var f = $field.val();
      if (typeof f == "string" && f.trim() == "") {
        isValid = false;
      }
    }
  }
  if ($field.attr('id') == "form-field-contact-email-repeat") {
    if ($('#form-field-contact-email').val() !== $field.val()) {
      isValid = false;
    }
  }
  if ($field.attr('id') == "form-field-contact-email") {
    validateField($('#form-field-contact-email-repeat'));
  }
  if ($field.attr('id') == 'form-field-birth-day' || $field.attr('id') == 'form-field-birth-month' || $field.attr('id') == 'form-field-birth-year') {
    var selectedValues = {
      day: parseInt($('#form-field-birth-day option:selected').text()) || null,
      month: $('#form-field-birth-month option:selected').prevAll().length || null,
      year: parseInt($('#form-field-birth-year option:selected').text()) || null
    };
    if (selectedValues.day && selectedValues.month && selectedValues.year) {
      var selectedDate = new Date(selectedValues.year, selectedValues.month - 1, selectedValues.day);
      var selectedMonthLength = new Date(selectedValues.year, selectedValues.month, 0).getDate();
      var today = new Date();
      today.setHours(0, 0, 0, 0);
      if (selectedMonthLength < selectedValues.day || selectedDate > today) {
        isValid = false;
        $('#form-field-birth-day').removeClass('with-success').addClass('with-error');
        $('#form-field-birth-month').removeClass('with-success').addClass('with-error');
        $('#form-field-birth-year').removeClass('with-success').addClass('with-error');
      } else {
        $('#form-field-birth-day').removeClass('with-error').addClass('with-success');
        $('#form-field-birth-month').removeClass('with-error').addClass('with-success');
        $('#form-field-birth-year').removeClass('with-error').addClass('with-success');
      }
    }
  }
  if (fieldType == "email") {
    var regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEx.test($field.val())) {
      isValid = false;
    }
  }
  if (!$field.is(":visible") || $field.parent().hasClass('hidden-vs')) {
    $field.removeClass('with-error').removeClass('with-success');
    return true;
  }
  if (fieldType == "checkbox") {
    $field = $field.closest('label');
  }
  if (fieldType == "radio") {
    if ($field.closest('.radio-column').length > 0) {
      $field = $field.closest('.radio-column').find('label');
    } else if ($field.closest('.insurance-question').length > 0) {
      $field = $field.closest('.insurance-question').find('label');
    }
  }
  if ($field.hasClass('hasDatepicker') && $field.val() != "" && $field.val() != null) {
    isValid = isValidDate($field.val());
  }
  if ($field.attr('id') == 'form-field-employment-position') {
    if ($field.val() == "" || $field.val() == "------" || $field.val().toLowerCase() != $('#form-field-employment-position-hidden option:checked').text().toLowerCase()) {
      isValid = false;
    } else {
      isValid = true;
    }
  }
  if (window.insuranceEnabled) {
    var insuranceValidity = validateInsuranceFields($field, event);
    if (insuranceValidity === false) {
      isValid = false;
    } else if (insuranceValidity === undefined) {
      return true;
    }
  }
  if ($field.attr('id') == 'form-field-flight-date' && $field.val() != "" && $field.val() != null && !showBasicDatePicker) {
    // input date
    var fieldDateValue = $('#' + $field.attr('id')).val();
    var fieldDateValueArray = fieldDateValue.split('/');
    var datexx = new Date(fieldDateValueArray[2], fieldDateValueArray[1], fieldDateValueArray[0]),
      dayxx = datexx.getDate(),
      monthxx = datexx.getMonth() + 1,
      yearxx = datexx.getFullYear();

    //today
    var ftoday = new Date();
    var fdd = ftoday.getDate();
    var fmm = ftoday.getMonth() + 1; //January is 0!
    var fyyyy = ftoday.getFullYear();
    var fdayw = ftoday.getDay();
    var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    var firstDate = new Date(yearxx, monthxx, dayxx);
    var secondDate = new Date(fyyyy, fmm, fdd); //today
    //console.log("firstDate: " + firstDate);
    //console.log("secondDate: " + secondDate);
    var diffDays = Math.round((firstDate.getTime() - secondDate.getTime()) / oneDay);
    if (diffDays > adddays) {
      isValid = true;
    } else {
      isValid = false;
    }
    if (yearxx < fyyyy) {
      isValid = false;
    }
  } else if ($field.attr('id') == 'form-field-flight-date' && $field.val() != "" && $field.val() != null && showBasicDatePicker) {
    var arrivalField = $('#form-field-flight-date');
    var arrivalFieldVal = arrivalField.val();
    var arrivalFieldValMin = arrivalField.attr('min');
    var arrivalFieldValMax = arrivalField.attr('max');
    var dVal = new Date(arrivalFieldVal);
    var dMin = new Date(arrivalFieldValMin);
    var dMax = new Date(arrivalFieldValMax);
    if (dMin.getTime() > dVal.getTime() || dMax.getTime() < dVal.getTime()) {
      isValid = false;
    } else {
      isValid = true;
    }
  }
  if (!['form[travel-insurance-start-date]', 'form[travel-insurance-end-date]', 'form[travel-insurance-answer]'].includes($field.attr('name'))) {
    if (isValid) {
      $field.removeClass('with-error').addClass('with-success');
      var errorText = $field.closest('.fields-column').find('.form-field-error');
      if (errorText) {
        errorText.text('').addClass('hidden');
      }
    } else {
      if (isRequired || $field.val() != "" && $field.val() != null) {
        $field.removeClass('with-success').addClass('with-error');
      } else {
        $field.removeClass('with-success');
      }
      $field.removeClass('with-success');
    }
  }

  // add or remove field from "validation-data"
  var validationData = $('#form-field-validation-data').val();
  var incomingFieldName = $field.attr('name');
  if (fieldType == "radio" || fieldType == "checkbox") {
    incomingFieldName = $($field.find('input')[0]).attr('name');
  }
  incomingFieldName = incomingFieldName.replace('form[', '');
  incomingFieldName = incomingFieldName.replace(']', '');
  if (validationData != undefined) {
    validationData = validationData.substring(0, validationData.length - 1);
    validationData = validationData.split(';');
    if (validationData[0] === '') {
      validationData.pop();
    }
    var incomingFieldNameArrayIndex = validationData.indexOf(incomingFieldName);
    if (isValid && incomingFieldNameArrayIndex === -1) {
      validationData.push(incomingFieldName);
    }
    if (!isValid && incomingFieldNameArrayIndex >= 0) {
      validationData.splice(incomingFieldNameArrayIndex, 1);
    }
  }
  validationData = validationData.join(';');
  $('#form-field-validation-data').val(validationData + ';');
  return isValid;
}
function getFieldsForm() {
  var $form = $('#form'),
    $fields = $form.find('input, select, textarea').not('input[type="file"]');
  var out = {};
  $fields.each(function () {
    var type = $(this).attr('type'),
      name = $(this).attr('name'),
      value = $(this).val();
    name = name.replace('form[', '').replace(']', '');
    switch (type) {
      case 'radio':
        {
          var isChecked = $(this).is(':checked');
          if (isChecked) {
            out[name] = value;
          } else if (out[name] === undefined) {
            out[name] = '';
          }
          break;
        }
      case 'checkbox':
        {
          var isChecked = $(this).is(':checked');
          if (isChecked) {
            out[name] = value;
          } else if (out[name] === undefined) {
            out[name] = '';
          }
          break;
        }
      default:
        {
          if (['date-of-arrival'].concat(_toConsumableArray(insuranceDateFieldsArray)).indexOf(name) !== -1) {
            if (!showBasicDatePicker && value.length > 0) {
              var dateSep = '-';
              var valueArr = value.split('/');
              value = valueArr[2] + dateSep + valueArr[1] + dateSep + valueArr[0];
            }
          }
          out[name] = value;
          break;
        }
    }
  });
  return JSON.stringify({
    'form': out
  });
}
function initializeApplicationInBase(dataObj) {
  var dataTypeObj = {
    type: debugMode === true ? "html" : "json"
  };
  $.ajax({
    url: "".concat(currentLang === 'en' ? '' : '/' + currentLang, "/form-apply-eta-initialize/"),
    dataType: dataTypeObj.type,
    method: "POST",
    async: false,
    data: {
      formData: dataObj
    },
    success: function success(response) {
      if (response.status === false) {
        console.log('error php');
        console.log(response.message);
      } else {
        if (debugMode === true) {
          console.log('debug mode initializeApplicationInBase');
          console.log(response);
        } else {
          // set localStorage
          try {
            dataObj = JSON.parse(dataObj);
          } catch (e) {
            console.log('invalid json');
          }
          var r_m = response.message.orderData;
          if (storageAvailable('localStorage') && typeof Storage !== "undefined") {
            var outObj = {
              initializeData: {
                hash: r_m.hash,
                application_no: r_m.id_str
              },
              fields: dataObj
            };
            var outData = JSON.stringify(outObj);
            if (!formsent) {
              localStorage.setItem(formLocalStorageKey, outData);
            }
            // Show application number
            showApplicationNo();
          } else {
            showApplicationNo({
              initializeData: {
                hash: r_m.hash,
                application_no: r_m.id_str
              }
            });
          }
        }
      }
    },
    error: function error(response) {
      console.error('error ajax');
      console.error(response);
    }
  });
}

// save all fields to base if storage is initialize else
function saveApplicationInBase() {
  if (formsent) {
    return;
  }
  var dataTypeObj = debugMode === true ? {
    type: "html"
  } : {
    type: "json"
  };
  if (!basicFieldsAreValid()) {
    return false;
  }
  var storageObj = isRecoveryPage ? getFormDataAsJson() : formIsInitialize();
  if (storageObj && !formsent) {
    var dataObj = JSON.parse(getFieldsForm());

    // update base & storage
    $.ajax({
      url: "".concat(currentLang === 'en' ? '' : ' /' + currentLang, "/form-apply-eta-field/"),
      dataType: dataTypeObj.type,
      method: "POST",
      data: {
        formData: dataObj,
        storageData: storageObj,
        isWidgetActive: showConsultationWidget
      },
      success: function success(response) {
        if (response.status == false) {
          console.log('error php');
          console.log(response.message);
        } else {
          if (debugMode === true) {
            console.log(response);
          } else {
            if (response.message != 1) {
              console.log('data was not update');
            }
            if (!isRecoveryPage) {
              try {
                var dataInStorage = storageDataToObj();
                if (dataInStorage === null) {
                  dataInStorage = getFormDataAsJson();
                }
                var outObj = {
                  initializeData: {
                    hash: dataInStorage.initializeData.hash,
                    application_no: dataInStorage.initializeData.application_no
                  },
                  fields: dataObj
                };
                var outData = JSON.stringify(outObj);
                if (storageAvailable('localStorage') && !formsent) {
                  localStorage.setItem(formLocalStorageKey, outData);
                }
              } catch (e) {
                console.log('invalid json');
              }
            }
          }
        }
      },
      error: function error(response) {
        console.error('error ajax');
        console.error(response);
      }
    });
  }
}
function initializeApplication() {
  if (basicFieldsAreValid() && !formIsInitialize()) {
    //generate data to save
    var dataObj = getFieldsForm();
    initializeApplicationInBase(dataObj);
  }
}
function basicFieldsAreValid() {
  var mailField = $('#form-field-contact-email'),
    firstFieldValue = mailField.val(),
    secondFieldValue = $('#form-field-contact-email-repeat').val(),
    birthDDField = $('#form-field-birth-day'),
    birthMMField = $('#form-field-birth-month'),
    birthYYField = $('#form-field-birth-year');
  var notEmpty = firstFieldValue !== '' && secondFieldValue !== '' && birthDDField.val() !== null && birthMMField.val() !== null && birthYYField.val() !== null;
  if (notEmpty) {
    var isValid = firstFieldValue === secondFieldValue && validateField(mailField) && validateField(birthDDField) && validateField(birthMMField) && validateField(birthYYField);
    return isValid;
  } else {
    return false;
  }
}
function isEUNationality() {
  var isEU = false;
  if (checkFastTrackUE === true) {
    var EUCountries = ["13287",
    // Austria
    "13288",
    // Belgium
    "13288",
    // Bulgaria
    "13316",
    // Croatia
    "13414",
    // Cyprus
    "13291",
    // Czechy
    "13293",
    // Denmark
    "13294",
    // Estonia
    "13297",
    // Finland
    "13298",
    // France
    "13300",
    // Greece
    "13311",
    // Spain
    "13305",
    // Netherlands
    "13296",
    // Lithuania
    "13289",
    // Luxembourg
    "13295",
    // Latvia
    "13304",
    // Malta
    "13299",
    // Germany
    "13307",
    // Poland
    "13308",
    // Portugal
    "13345",
    // Romania
    "13292",
    // Slovakia
    "13319",
    // Slovenia
    "13313",
    // Sweden
    "13301",
    // Hungary
    "13303" // Italy
    ];

    availableNationalityFieldNames.forEach(function (name) {
      if (EUCountries.includes($('select[name="form[' + name + ']"]').val())) {
        isEU = true;
      }
    });
  }
  return isEU;
}
function formIsInitialize() {
  if (clearLocalStorage) {
    localStorage.removeItem(formLocalStorageKey);
  }
  var arrayStorageData = storageDataToObj();
  if (arrayStorageData === null) {
    arrayStorageData = getFormDataAsJson();
  }
  var status = arrayStorageData !== null && arrayStorageData.initializeData.hash !== '' ? arrayStorageData : false;
  return status;
}
function storageDataToObj() {
  if (typeof Storage === "undefined" || !storageAvailable('localStorage')) {
    return null;
  }
  var out;
  var data = localStorage.getItem(formLocalStorageKey);
  if (typeof data === "undefined" || data === "" || data === null) {
    return null;
  }
  try {
    out = JSON.parse(data);
  } catch (e) {
    console.log('invalid json');
    localStorage.setItem(formLocalStorageKey, "");
    out = null;
  }
  return out;
}
function daysBetween(date1, date2) {
  return Math.floor((Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate()) - Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())) / (1000 * 60 * 60 * 24));
}
function checkDate(date) {
  var separator = "/",
    today = new Date();
  date = date.split("/");
  date = new Date(date[2] + separator + date[1] + separator + date[0]);
  var diffDays = daysBetween(today, date);
  if (diffDays < 0 || adddays > diffDays) return false;
  return true;
}
function validateBeforeSubmit(e) {
  var errors = 0;
  var $form = $('#form');
  var $fields = $form.find('[data-required]');
  var focused = false;
  var $validationDataInput = $('[name="form[validation-data]"]');
  if ($("#form-field-flight-date").is(':visible') && !$("#form-field-flight-date").parent().hasClass('hiddens-vs') && $("#form-field-flight-date").val() == '') {
    $('html, body').scrollTop($("#form-field-flight-date").offset().top - 250);
    $("#form-field-flight-date").focus();
    $("#form-field-flight-date").addClass('with-error');
    errors++;
  }

  //Germany O0 FIX
  var ver_pass_id = $('#form-field-passport-country option:selected').val();
  var objpass = $('#form-field-passport-number');
  if (ver_pass_id == '13299') {
    var passport_number = $('#form-field-passport-number').val();
    var mao = passport_number.indexOf("O");
    if (mao >= 0) {
      //alert('Your passport number should not have O letter in it! Please use a digit 0!');
      alert('Bitte beachten Sie, dass es sich bei der Eingabe der Reisepassnummer nur um die Zahl 0 handelt, nie um den Buchstaben O.');
      $('html, body').animate({
        scrollTop: $("#form-field-passport-number").offset().top - 250
      }, 500);
      objpass.focus();
      objpass.css('border-color', 'red');
      e.preventDefault();
      return false;
    }
  }
  $validationDataInput.val("");
  $('.with-error').removeClass('with-error');
  $fields.each(function () {
    var $obj = $(this);
    var visible = $obj.is(':visible') && !$obj.parent().hasClass('hidden-vs');
    if (visible) {
      // match form name
      var regex = new RegExp(/^form\[(.*?)\]$/);
      var match = regex.exec($obj.attr('name'));
      var isValid = validateField($obj, e);
      $validationDataInput.val($validationDataInput.val() + match[1] + ";");
      if (!isValid) {
        if (!focused) {
          $('html, body').scrollTop($obj.offset().top - 250);
          $obj.focus();
          focused = true;
        }
        errors++;
      }
    }
  });
  return errors;
}
function processForm() {
  // prevent to past text to email repeat field
  $('#form-field-contact-email-repeat').on('paste', function (e) {
    e.preventDefault();
  });
  $('#form button[type=submit]').click(function (e) {
    if (showConsultationWidget === true) {
      e.preventDefault();
      return;
    }
    if (document.querySelector('input[name="form[terms]"]').checkValidity) {
      if (validateBeforeSubmit(e) === 0) {
        if (document.querySelector('input[name="form[terms]"]').checkValidity() === false) {
          $('input[name="form[terms]"]').closest('label').addClass('with-error').removeClass('with-success');
        }
      } else {
        e.preventDefault();
        $('#form').trigger('submit');
      }
    }
  });
  $('#form').submit(function (e) {
    e.preventDefault();
    saveApplicationInBase();
    if ($('[name="form[in7days]"][value="0"]:checked').length > 0) {
      var dataVal = $('#form-field-flight-date').val();
      var dateParts = dataVal.split('-');
      var date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
      var isDate = showBasicDatePicker && date != "Invalid Date" && date.getTime() > Date.now() || !showBasicDatePicker && isValidDate(dataVal);
      if (!isDate) {
        $('#form-field-flight-date').val(addToToday(7, showBasicDatePicker));
      }
      $('#form-field-flight-date').attr('data-required', 'true');
    }
    var $form = $(this);
    var $submitBtn = $form.find('[type="submit"]');
    var errors = validateBeforeSubmit(e);
    if (errors == 0) {
      var data = isRecoveryPage ? getFormDataAsJson() : storageDataToObj();
      if (data === null) {
        data = getFormDataAsJson();
      }
      if (data !== null && data.initializeData !== undefined) {
        // send form
        $submitBtn.prop('disabled', true).addClass('loading');
        formsent = true;
        $.ajax({
          url: $form.attr('action'),
          dataType: "json",
          method: "POST",
          data: {
            hash: data.initializeData.hash,
            testMode: 0
          },
          success: function success(response) {
            if (response.status == false) {
              formsent = false;
              alert(formErrorMessage);
              $submitBtn.prop('disabled', false).removeClass('loading');
              console.log(response.msg);
            } else {
              if (storageAvailable('localStorage') && typeof Storage !== "undefined") {
                localStorage.removeItem(formLocalStorageKey);
              }
              window.onbeforeunload = null;
              window.location = response.redirectURL;
            }
          },
          error: function error(response) {
            alert(formErrorMessage);
            $submitBtn.prop('disabled', false).removeClass('loading');
          }
        });
      }
    } else {
      alert(translations['form field warning']);
    }
  });
}
function showApplicationNo(data) {
  if (data === undefined) {
    var data = storageDataToObj();
  }
  if (data !== null && data.initializeData !== undefined) {
    var AppNumber = data.initializeData.application_no;
    var AppHash = data.initializeData.hash;
    if (typeof AppNumber !== "undefined" && AppNumber !== '' && typeof AppHash !== "undefined" && AppHash !== '') {
      $('.form-application-content-no .form-application-no').remove();
      $('.form-application-content-no').append($('<span class="form-application-no">').text(AppNumber), $('<span class="form-application-hash hidden">').text(AppHash)).removeClass('hidden');
    }
  }
}
function getFormDataAsJson() {
  var appNumber = $('.form-application-no').eq(0).text(),
    appHash = $('.form-application-hash').eq(0).text();
  return {
    initializeData: {
      application_no: appNumber,
      hash: appHash
    },
    fields: getFieldsForm()
  };
}
function additionalCitizenshipHiddenForm() {
  // Hide show form
  var $formSelector = $('[name="form[additional-citizenship-q]"]');
  var $rows = $('.citizenship-answer');
  var $question = $('.citizenship-question');
  var maxFields = 10;
  function addField() {
    if (currentFields + 1 > maxFields) return;
    currentFields += 1;
    $('#form-field-additional-citizenship-new-' + currentFields).parent().removeClass('hidden');
  }
  function removeField() {
    if (currentFields <= 1) {
      return;
    }
    $('#form-field-additional-citizenship-new-' + currentFields).parent().addClass('hidden');
    $('#form-field-additional-citizenship-new-' + currentFields).val('').trigger('change').removeClass('with-error').removeClass('with-success');
    currentFields -= 1;
  }
  $formSelector.change(function () {
    var checked = $('[name="form[additional-citizenship-q]"]:checked').val() == 1;
    if (!checked) {
      $question.addClass('group-spacer');
      $rows.addClass('hidden-padding hidden');
      $('#form-field-additional-citizenship-new-1').val('').removeClass('with-error').removeClass('with-success');
      for (var i = 1; i <= maxFields; i++) {
        removeField();
      }
    } else {
      $question.removeClass('group-spacer');
      $rows.removeClass('hidden-padding hidden');
      $('#form-field-additional-citizenship-new-' + i).removeClass('with-error').removeClass('with-success');
    }
  }).trigger('change');
  var $btnP = $('<button />').addClass('submit-btn citizenship-btn btn-add').html('+');
  var $btnM = $('<button />').addClass('submit-btn citizenship-btn btn-remove').html('-');
  $('.citizenship-answer').append($btnP).append($btnM);
  $(document).on('click', '.citizenship-btn', function (e) {
    e.preventDefault();
    var $obj = $(this);
    var isAdd = $obj.hasClass('btn-add');
    if (isAdd) {
      addField();
    } else {
      removeField();
    }
  });
}
function minorHiddenForm() {
  var $formSelector = $('[name="form[minor]"]');
  var $rows = $('.minor-employment-hide');
  $formSelector.change(function () {
    var $obj = $(this);
    var checked = $('[name="form[minor]"]:checked').val() == 1;
    if (checked) {
      $rows.addClass('hidden');
      $('#form-field-employment-profession').val('').trigger('change');
    } else {
      $rows.removeClass('hidden');
    }
  }).trigger('change');
}
function invoiceHiddenForm() {
  var $iformSelector = $('[name="form[invoice]"]');
  $iformSelector.change(function () {
    var $obj = $(this);
    var checked = $obj.prop('checked');
    if (checked) {
      $('.invoice-hidden-row').removeClass('hidden');
    } else {
      $('.invoice-hidden-row').addClass('hidden');
      $('.invoice-hidden-row').find('input').val('').trigger('change').removeClass('with-success').removeClass('with-error');
    }
    formLabelsEqual();
  }).trigger('change');
}
function hiddenForm1() {
  var $formSelector = $('[name="form[long-term]"]');
  $formSelector.change(function () {
    var $obj = $(this);
    var $field1 = $('.hidden-uci-number');
    if ($obj.val() == 1) {
      $field1.removeClass('hidden');
    } else {
      $field1.addClass('hidden');
      $field1.find('input').val('').removeClass('with-success').removeClass('with-error');
    }
  }).trigger('change');
}
function hiddenForm2() {
  var $formSelector1 = $('[name="form[question-tuberculosis]"]');
  $formSelector1.change(function () {
    var $field = $('.hidden-tuberculosis-details');
    var $field2 = $('.hidden-tuberculosis-diagnose');
    var checked = $('[name="form[question-tuberculosis]"]:checked').val();
    if (checked == 1) {
      $field.removeClass('hidden');
      $field2.removeClass('hidden');
    } else {
      $field.addClass('hidden');
      $field2.addClass('hidden');
    }
  }).trigger('change');
}
function hiddenForm3() {
  var $formSelector = $('[name="form[question-regular-treatment]"]');
  var $targetRow = $('.row-kidney-disease');
  $formSelector.change(function () {
    formTreatmentState(-1);
    formTravelForTreatmentState(1);
    var $field1 = $('.hidden-kidney-disease');
    if ($(this).val() == 1) {
      $field1.removeClass('hidden');
      $targetRow.removeClass('hidden');
      $('[name="form[question-kidney-disease]"]').trigger('change');
    } else {
      $field1.addClass('hidden');
      $targetRow.addClass('hidden');
    }
  });
}
function hiddenForm4() {
  var $formSelector = $('[name="form[question-kidney-disease]"]');
  $formSelector.change(function () {
    var currentValue = $('[name="form[question-kidney-disease]"]:checked').val();
    formTravelForTreatmentState(currentValue);
    formTreatmentState(-1);
  }).trigger('change');
}
function hiddenForm5() {
  var $formSelector = $('[name="form[question-travel-for-treatment]"]');
  $formSelector.change(function () {
    var currentValue = $('[name="form[question-travel-for-treatment]"]:checked').val();
    formTreatmentState(currentValue);
  }).trigger('change');
}
function hiddenForm6() {
  // USA state
  $('.state-empl').addClass('hidden');
  // work
  $('#form-field-employment-company-country').change(function () {
    var countryID = $(this).find('option:selected').val();
    if (countryID == '13452') {
      $('.state-empl').removeClass('hidden');
    } else {
      $('.state-empl').addClass('hidden').find('select').val("");
    }
  });

  // contact
  $('#form-field-contact-country').change(function () {
    var countryID = $(this).find('option:selected').val();
    if (countryID == '13452') {
      $('.state-contact').removeClass('hidden');
    } else {
      $('.state-contact').addClass('hidden');
    }
  });
}

// USA state

// work
$('#form-field-employment-company-country').change(function () {
  var countryID = $('#form-field-employment-company-country option:selected').val();
  if (countryID == '13452') $('.state-empl').removeClass('hidden');
  if (countryID != '13452') $('.state-empl').addClass('hidden');
});

// contact
$('#form-field-contact-country').change(function () {
  var countryID = $('#form-field-contact-country option:selected').val();
  if (countryID == '13452') $('.state-contact').removeClass('hidden');
  if (countryID != '13452') $('.state-contact').addClass('hidden');
});

// partner widget
$('#form-field-visa-type').change(function () {
  var visaType = $(this).val();
  var consultationVisaTypes = ['work', 'student', 'family', 'partner', 'investor', 'other', 'consultation'];
  formPartnerWidget(consultationVisaTypes.includes(visaType));
}).trigger('change');
function visaRejectedAndJailAnswer() {
  var visaRejectedIsChecked = false;
  var arrestedIsChecked = false;
  if ($('[name="form[question-visa-rejected]"]').closest('.block-on-yes').length !== 0 && $('[name="form[question-jail]"]').closest('.block-on-yes').length !== 0) {
    $('[name="form[question-visa-rejected]"], [name="form[question-jail]"]').change(function () {
      visaRejectedIsChecked = $('[name="form[question-visa-rejected]"]:checked').val() === '1';
      arrestedIsChecked = $('[name="form[question-jail]"]:checked').val() === '1';
      if (visaRejectedIsChecked || arrestedIsChecked) {
        $('.form button[type="submit"]').addClass("btn-gray").prop('disabled', true).html($('.form button[type="submit"]').data('secondary-text'));
      } else {
        $('.form button[type="submit"]').removeClass("btn-gray").prop('disabled', false).html($('.form button[type="submit"]').data('primary-text'));
      }
    }).trigger('change');
    $('[name="form[question-visa-rejected]"]').change(function () {
      if ($('[name="form[question-visa-rejected]"]:checked').val() === "1") {
        $('#info-container-question-visa-rejected').removeClass('hidden');
      } else {
        $('#info-container-question-visa-rejected').addClass('hidden');
      }
    }).trigger('change');
    $('[name="form[question-jail]"]').change(function () {
      if ($('[name="form[question-jail]"]:checked').val() === "1") {
        $('#info-container-question-jail').removeClass('hidden');
      } else {
        $('#info-container-question-jail').addClass('hidden');
      }
    }).trigger('change');
  }
}
function jobHiddenForm() {
  var $toHide = $('.employment-field');
  var $selector = $('#form-field-employment-profession');
  $selector.change(function () {
    var currentVal = $(this).val();
    if (currentVal == '' || currentVal == null || currentVal == 14842 || currentVal == 14839 || currentVal == 14840) {
      $toHide.addClass('hidden');
      $toHide.find('input, select').val("").removeClass('with-error with-success');
    } else {
      $toHide.removeClass('hidden');
      $('.state-empl').addClass('hidden');
      if (currentVal == 14841) {
        $('#form-field-employment-position').val("").parent().addClass('hidden');
      } else {
        $('#form-field-employment-position').parent().show();
      }
    }
    formLabelsEqual();
  }).trigger("change");
}
function formTravelForTreatmentState(state) {
  var $targetRow = $('.row-travel-for-treatment');
  var $field1 = $('.hidden-travel-for-treatment');
  if (state == 0) {
    $field1.removeClass('hidden');
    $targetRow.removeClass('hidden');
  } else if (state == 1) {
    $field1.addClass('hidden');
    $targetRow.addClass('hidden');
  }
}
function formPartnerWidget() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : showConsultationWidget;
  showConsultationWidget = state;
  isVisaPlaceNationality();
  if (state || visaPlaceNationality || southAmericaNoneOfAbove) {
    $('.hiddenWithWidget').hide();
    $('.visibleWithWidget').show();
  } else {
    $('.hiddenWithWidget').show();
    $('.visibleWithWidget').hide();
  }
}
function formTreatmentState(state) {
  var $targetRow = $('.row-hospitalization, .row-insurance');
  var $targetRow2 = $('.row-treatment-plan');
  var $field1 = $('.hidden-treatment-plan');
  var $field2 = $('.hidden-hospitalization');
  var $field3 = $('.hidden-insurance');
  if (state == 1) {
    $field1.removeClass('hidden');
    $field2.addClass('hidden');
    $field3.addClass('hidden');
    $targetRow.addClass('hidden');
    $targetRow2.removeClass('hidden');
  } else if (state == 0) {
    $field1.addClass('hidden');
    $field2.removeClass('hidden');
    $field3.removeClass('hidden');
    $targetRow.removeClass('hidden');
    $targetRow2.addClass('hidden');
  } else if (state == -1) {
    $field1.addClass('hidden');
    $field2.addClass('hidden');
    $field3.addClass('hidden');
    $targetRow.addClass('hidden');
    $targetRow2.addClass('hidden');
  }
}
cvvInfo();
function cvvInfo() {
  var circle = $('.green-hint');
  var infoBox = $('.cvv-information');
  if (window.innerWidth <= 800) {
    $(window).on('click', function (event) {
      if (event.target && !event.target.classList.contains('green-hint')) {
        infoBox.addClass('hidden');
      }
    });
    circle.on('click', function (event) {
      infoBox.removeClass('hidden');
    });
  } else {
    circle.on('mouseover', function (event) {
      infoBox.removeClass('hidden');
    });
    circle.on('mouseout', function (event) {
      infoBox.addClass('hidden');
    });
  }
}
function showError(target, event, message) {
  event.preventDefault();
  var errorText = target.parentNode.querySelector('.form-field-error');
  target.classList.remove('with-success');
  target.classList.add('with-error');
  errorText.classList.remove('hidden');
  errorText.innerText = message;
}
$.fn.preventNonLatin = function () {
  /*
  CYRYLIC:
      u0400 - u052F
      u1C80 - u1C88
      u2DE0 - u2DFF
      uA640 - uA69F
  ARABIC SIGNS:
      u0600 - u077F
      u08A0 - u08BD
      u08D3 - u08FF
  CHINESE SIGNS:
      u2E80 - u2E99
      u2E9B - u2EF3
      u2F00 - u2FD5
      u3000 - u303F
      u3105 - u312F
      u3131 - u318E
      u3190 - u31BA
      u31C0 - u31E3
      u3200 - u321E
      u3220 - u32FE
      u3300 - u9FFF
      uA840 - uA877
      uF900 - uFA6D
      uFA70 - uFAD9
  */
  var nonLatinPattern = /[\u0400-\u052f\u1C80-\u1C88\u2DE0-\u2DFF\uA640-\uA69F\u0600-\u077F\u08A0-\u08BD\u08D3-\u08FF\u2E80-\u2E99\u2E9B-\u2EF3\u2F00-\u2FD5\u3000-\u303F\u3105-\u312F\u3131-\u318E\u3190-\u31BA\u31C0-\u31E3\u3200-\u321E\u3220-\u32FE\u3300-\u9FFF\uA840-\uA877\uF900-\uFA6D\uFA70-\uFAD9]/;
  this.keypress(function (e) {
    if (e.key !== undefined && e.key.replace(nonLatinPattern, '') == '') {
      showError(this, e, translations['form label nonlatin error']);
    }
  });
  this.on('paste', function (e) {
    var pasteData;
    if (window.clipboardData !== undefined && window.clipboardData.getData !== undefined) {
      // IE
      pasteData = window.clipboardData.getData('text');
    } else if (e.originalEvent.clipboardData !== undefined && e.originalEvent.clipboardData.getData !== undefined) {
      pasteData = e.originalEvent.clipboardData.getData('text');
    }
    if (pasteData.match(nonLatinPattern)) {
      showError(this, e, translations['form label nonlatin error']);
    }
  });
};
$('#form-field-name').preventNonLatin();
$('#form-field-surname').preventNonLatin();
var getPrices = function getPrices(resolve) {
  var req = new XMLHttpRequest();
  var formdata = new FormData();
  var storageData = storageDataToObj();
  storageData && storageData.initializeData && formdata.append('idStr', storageData.initializeData.application_no);
  formdata.append('currency', 'EUR');
  req.open('POST', '/prices/', true);
  req.onreadystatechange = function () {
    if (req.readyState === 4) {
      if (req.status === 200) {
        resolve(JSON.parse(req.responseText));
      } else {
        var xhr = new XMLHttpRequest();
        xhr.open('POST', '/logger.php', true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhr.send("message=CANNOT GET PRICES");
      }
    }
  };
  req.send(formdata);
};
if (window.expressProcessingEnabled) {
  var updateExpressProcessingPrice = function updateExpressProcessingPrice() {
    $('.expressProcessing .form-row-heading').text($('.expressProcessing .form-row-heading').text().replace('[PRICE]', prices.express_processing.toFixed(2) + ' ' + prices.currency));
  };
  if (window.insuranceEnabled) {
    $(document).ready(updateExpressProcessingPrice);
  } else {
    var loadPrices = function loadPrices() {
      getPrices(function (data) {
        prices = data;
        $(document).ready(updateExpressProcessingPrice);
      });
    };
  }
  window.addEventListener('load', loadPrices);
}
function toggleFastTrack() {
  var portId = $('#form-field-port-of-arrival').val();
  var fastTrackPortsId = Object.keys(fastTrackPorts);
  var isPortEligibleForFastTrack = fastTrackPortsId.includes(portId) && !showConsultationWidget && !isEUNationality();
  console.log(isPortEligibleForFastTrack);
  showFastTrackQuestion(isPortEligibleForFastTrack);
}
function showFastTrackQuestion(isEnabled) {
  if (isEnabled) {
    $(".fast-track-question").removeClass('hidden');
    showFastTrackFields();
  } else {
    $(".fast-track-question").addClass('hidden');
    $('input[name="form[fast-track]"][value="1"]').prop('checked', false);
    $('input[name="form[fast-track]"][value="0"]').prop('checked', false);
    hidFastTrackFlightFields();
  }
}
function showFastTrackFields() {
  var fastTrackQuestionRadio = $('[name="form[fast-track]"]');
  fastTrackQuestionRadio.on('change', function () {
    if ($('[name="form[fast-track]"]:checked').val() === "1") {
      $('.fast-track-cnt').removeClass('hidden');
      $('.fast-track-flight-fields').removeClass('hidden');
      setFastTrackPrice();
    } else {
      $('.fast-track-cnt').addClass('hidden');
      hidFastTrackFlightFields();
    }
  }).trigger('change');
  fastTrackQuestionRadio.closest('.fields-column').removeClass('with-error');
  fastTrackQuestionRadio.closest('label').removeClass('with-error');
}
function setFastTrackPrice() {
  var portId = $('#form-field-port-of-arrival').val();
  var productPrice = fastTrackPorts[portId] ? fastTrackPorts[portId].productPrice : null;
  if (productPrice) {
    $('.fast-track-price').removeClass('hidden');
    $('.form-fast-track-price').text(productPrice + ' EUR');
  }
}
function hidFastTrackFlightFields() {
  $('.fast-track-flight-fields').addClass('hidden');
  $('#form-field-flight-number').val('');
  $('#form-field-flight-time').val('');
}